<template>
  <v-card>
    <v-card-title
      class="title primary white--text"
    >
      <v-icon
        dark
        left
      >
        {{ $vuetify.icons.values.novedad }}
      </v-icon>
      Parte de novedad en curso
    </v-card-title>
    <v-card-text
      class="pa-2"
      v-html="title"
    />
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        text
        small
        @click.stop="clickParteNovedad"
      >
        Ir al parte
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { get } from 'vuex-pathify'

export default {
  computed: {
    presenciaIdparteNovedad: get('presencia/idparteNovedad'),
  },
  created () {
    this.title = `
      Parte de novedad Nº<strong>${this.presenciaIdparteNovedad}</strong>`
  },
  methods: {
    clickParteNovedad () {
      this.$appRouter.push({
        name: 'partesnovedad__parte-novedad-view',
        params: { idparte_novedad: this.presenciaIdparteNovedad }
      })
    }
  }
}
</script>
